<template>
  <section class="container2">
    <!--left part - card -->
    <div
      class="w-full py-17.5 lg:pb-23.5 lg:pt-28"
      :style="{
        backgroundColor: lgAndUp
          ? data?.slides?.[0].backgroundColor?.hexa || '#F4EEE8'
          : data?.slides?.[0].backgroundColorMobile?.hexa ||
            data?.slides?.[0].backgroundColor?.hexa ||
            '#F4EEE8',
      }"
    >
      <div
        class="flex lg:px-18 lg:pl-[239px]"
        :class="[
          data.rightSide ? 'lg:flex-row' : 'lg:flex-row-reverse',
          data.bottomMobile ? 'max-lg:flex-col' : 'max-lg:flex-col-reverse',
        ]"
      >
        <div
          class="relative justify-end max-lg:container lg:flex lg:w-full lg:items-center lg:justify-center lg:py-0"
        >
          <div
            class="content break-words bg-transparent text-gray-dark lg:absolute lg:right-0 lg:w-[520px] lg:max-w-[520px] lg:bg-white lg:pl-10 lg:pr-25 lg:text-xl 3xl:-left-10 3xl:right-auto"
            @click="contentLink"
            v-html="data?.slides?.[0].text"
          />
        </div>
        <!--right part - Image -->
        <div
          class="w-full flex-shrink-0 max-lg:container lg:max-w-[657px] lg:px-0 3xl:max-w-[900px]"
          :style="{
            height: height,
          }"
        >
          <video
            v-if="data?.file?.media_type?.includes(assetsTypes.video)"
            class="h-full w-full object-cover"
            :src="
              lgAndDown
                ? data?.fileMobile?.url || data?.file?.url
                : data?.file?.url
            "
            autoplay
            loop
            muted
            playsinline
          />
          <UiLazyImg
            v-else
            class="h-full w-full"
            :src="data?.file?.url || ''"
            :src-mobile="data?.fileMobile?.url || ''"
            :alt="
              data?.file?.alt ||
              removeHTMLTagFromString(data?.slides?.[0].title || '') ||
              $t('image')
            "
            cover
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { assetsTypes, BlockSliderContent } from "ecom-types";

const props = defineProps<{ data: BlockSliderContent }>();

const contentLink = useContentLink;

const { lgAndUp, lgAndDown } = useAppBreakpoints();

const height = computed(() => {
  if (lgAndUp.value) {
    return props.data?.slides?.[0].height?.desktop || "600px";
  } else if (lgAndDown.value && props.data?.slides?.[0].height?.mobile) {
    return props.data?.slides?.[0].height?.mobile || "100%";
  } else {
    return "auto";
  }
});
</script>

<style scoped>
/*.content:deep(.button) {
  @apply flex max-w-fit items-center justify-center bg-button-primary px-[25px] py-4 font-futura text-sm uppercase text-white outline-0 transition-all hover:bg-button-primary-hover;
}
.content:deep(.button.outline) {
  @apply border !border-button-secondary !bg-transparent !text-button-secondary hover:!bg-button-secondary hover:!text-white;
}*/
</style>
